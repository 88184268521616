import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";

@Injectable()
export class CrashlogConfigProvider {
    public crashlogApiTrackEndpoint() {
        return environment.crashlogApiTrackEndpoint;
    }
    public applicationToken() {
        return environment.crashlogApiToken;
    }
    public applicationVersion() {
        return environment.version;
    }
    public applicationEnvironment() {
        return "web";
    }
    public localStorageLogsKey() {
        return "daumenhoch-admin-crashes";
    }
    public logErrorInConsole() {
        return environment.logErrorInConsole;
    }
}

import { Exclude, Type } from "class-transformer";
import { nonLambdaReturn } from "@impacgroup/angular-baselib";

export class IPointOfInterestTranslation {
    _id?: string;
    iso = "";
    information = "";
}

export interface IPointOfInterestPicture {
    picture: string;
    mimetype: string;
}

export enum MarkerType {
    standard = "standard",
    christmas_tree = "christmas_tree"
}

export class PointOfInterest {
    _id?: string;

    name = "";
    country = "";
    street = "";
    postalcode = "";
    city = "";
    teaser: IPointOfInterestPicture;
    logo: IPointOfInterestPicture;
    thumbnail: IPointOfInterestPicture;
    owner = "";
    phone = "";
    email = "";
    website = "";
    tags: string[] = [];

    translations: IPointOfInterestTranslation[] = [];

    category = "";
    gpsLat = 0;
    gpsLong = 0;
    markerType: MarkerType = MarkerType.standard;

    version?: number;

    @Type(nonLambdaReturn(Date))
    @Exclude({ toPlainOnly: true })
    created?: Date;

    @Type(nonLambdaReturn(Date))
    @Exclude({ toPlainOnly: true })
    updated?: Date;
}

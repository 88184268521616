import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, BaseComponent, BaseComponentMode } from "@impacgroup/angular-baselib";
import { PointOfInterest, MarkerType } from "../../api-models/PointOfInterest";
import { PointsOfInterestService, ICountry, ILanguage } from "./pointsofinterest.service";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin, Observable } from "rxjs";
import { Category } from "../../api-models/Category";

@Component({
    selector: "app-pointofinterestdetail",
    templateUrl: "./pointofinterestdetail.component.html",
    styleUrls: ["pointofinterestdetail.component.scss"]
})
export class PointOfInterestDetailComponent extends BaseComponent implements OnInit {
    private pointOfInterestId: string = null;
    public pointOfInterest: PointOfInterest = null;
    public tags = "";

    public selectedTeaserImage: FileList = null;
    public teaserPreviewImage: string | ArrayBuffer = null;

    public selectedLogoImage: FileList = null;
    public logoPreviewImage: string | ArrayBuffer = null;

    public selectedThumbnailImage: FileList = null;
    public thumbnailPreviewImage: string | ArrayBuffer = null;

    public countries: ICountry[] = [];
    public languages: ILanguage[] = [];
    public categories: Category[] = [];
    public markerTypes: MarkerType[] = [];

    constructor(
        private readonly route: ActivatedRoute,
        private readonly pointsOfInterestService: PointsOfInterestService,
        private readonly router: Router,
        private readonly alertService: AlertService,
        private readonly translateService: TranslateService,
        private readonly _location: Location
    ) {
        super();

        this.markerTypes = Object.keys(MarkerType).map((key) => MarkerType[key]);

        this.subscriptions.push(
            this.pointsOfInterestService.languages().subscribe((languages) => {
                this.languages = languages;

                this.subscriptions.push(
                    this.route.params.subscribe((params) => {
                        this.pointOfInterestId = params.id;
                        if (this.pointOfInterestId) {
                            this.initEditMode();
                        } else {
                            this.initAddMode();
                        }
                    })
                );
            })
        );
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.pointsOfInterestService.countries().subscribe((result) => {
                this.countries = result;
            })
        );

        this.subscriptions.push(
            this.pointsOfInterestService.categories().subscribe((result) => {
                this.categories = result;
            })
        );
    }

    initAddMode() {
        this.MODE = BaseComponentMode.ADD;
        this.pointOfInterest = new PointOfInterest();

        this.languages.forEach((language) => {
            this.pointOfInterest.translations.push({
                iso: language.code,
                information: ""
            });
        });
    }

    initEditMode() {
        this.MODE = BaseComponentMode.EDIT;

        this.selectedTeaserImage = null;
        this.selectedLogoImage = null;
        this.selectedThumbnailImage = null;

        this.subscriptions.push(
            this.pointsOfInterestService.detail(this.pointOfInterestId).subscribe(
                (result) => {
                    this.pointOfInterest = result;
                    if (result.tags) {
                        this.tags = result.tags.join(", ");
                    }

                    if (this.pointOfInterest.teaser && this.pointOfInterest.teaser.picture) {
                        this.subscriptions.push(
                            this.pointsOfInterestService.downloadPicture(this.pointOfInterestId).subscribe((teaser) => {
                                const reader = new FileReader();

                                reader.readAsDataURL(teaser);
                                reader.onload = (_event) => {
                                    this.teaserPreviewImage = reader.result;
                                };
                            })
                        );
                    }

                    if (this.pointOfInterest.logo && this.pointOfInterest.logo.picture) {
                        this.subscriptions.push(
                            this.pointsOfInterestService.downloadLogo(this.pointOfInterestId).subscribe((logo) => {
                                const reader = new FileReader();

                                reader.readAsDataURL(logo);
                                reader.onload = (_event) => {
                                    this.logoPreviewImage = reader.result;
                                };
                            })
                        );
                    }

                    if (this.pointOfInterest.thumbnail && this.pointOfInterest.thumbnail.picture) {
                        this.subscriptions.push(
                            this.pointsOfInterestService.downloadThumbnail(this.pointOfInterestId).subscribe((thumbnail) => {
                                const reader = new FileReader();

                                reader.readAsDataURL(thumbnail);
                                reader.onload = (_event) => {
                                    this.thumbnailPreviewImage = reader.result;
                                };
                            })
                        );
                    }

                    const translations = [];
                    this.languages.forEach((language) => {
                        const found = this.pointOfInterest.translations.find((translation) => translation.iso === language.code);
                        if (found) {
                            translations.push(found);
                        } else {
                            translations.push({
                                iso: language.code,
                                information: ""
                            });
                        }
                    });
                    this.pointOfInterest.translations = translations;
                },
                (err) => {
                    this.router.navigate(["../"], { relativeTo: this.route });
                }
            )
        );
    }

    languageNameByIso(isoCode: string) {
        const found = this.languages.find((lang) => lang.code === isoCode);
        return found ? found.name : "";
    }

    // handle teaser file selection
    handleTeaserFileInput(files: FileList) {
        if (files.length === 0) {
            return;
        }

        const mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            this.alertService.addCustomErrorByI18nId("pointsofinterest.detail.validation.picture");
            return;
        }

        const reader = new FileReader();
        this.selectedTeaserImage = files;

        reader.readAsDataURL(files[0]);
        reader.onload = (_event) => {
            this.teaserPreviewImage = reader.result;
        };
    }

    // handle logo file selection
    handleLogoFileInput(files: FileList) {
        if (files.length === 0) {
            return;
        }

        const mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            this.alertService.addCustomErrorByI18nId("pointsofinterest.detail.validation.logo");
            return;
        }

        const reader = new FileReader();
        this.selectedLogoImage = files;

        reader.readAsDataURL(files[0]);
        reader.onload = (_event) => {
            this.logoPreviewImage = reader.result;
        };
    }

    // handle thumbnail file selection
    handleThumbnailFileInput(files: FileList) {
        if (files.length === 0) {
            return;
        }

        const mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            this.alertService.addCustomErrorByI18nId("pointsofinterest.detail.validation.thumbnail");
            return;
        }

        const reader = new FileReader();
        this.selectedThumbnailImage = files;

        reader.readAsDataURL(files[0]);
        reader.onload = (_event) => {
            this.thumbnailPreviewImage = reader.result;
        };
    }

    save() {
        this.pointOfInterest.tags = this.tags.split(",").map((tag) => tag.trim());

        if (this.MODE === BaseComponentMode.ADD) {
            // teaser picture is mandatory
            if (!this.selectedTeaserImage || this.selectedTeaserImage.length === 0) {
                this.alertService.addCustomError(this.translateService.instant("pointsofinterest.detail.validation.picture"));
                return;
            }

            this.subscriptions.push(
                this.pointsOfInterestService.add(this.pointOfInterest).subscribe((result) => {
                    const uploadSubscriptions = this.uploadPictureFiles(result);

                    if (uploadSubscriptions.length > 0) {
                        this.subscriptions.push(
                            forkJoin(uploadSubscriptions).subscribe(
                                (results) => {
                                    this.alertService.addSuccess(this.translateService.instant("pointsofinterest.detail.add.success"));
                                    this.router.navigate(["../"], { relativeTo: this.route });
                                },
                                (error) => {
                                    this.router.navigate(["../", result._id], { relativeTo: this.route });
                                }
                            )
                        );
                    } else {
                        this.alertService.addSuccess(this.translateService.instant("pointsofinterest.detail.add.success"));
                        this.router.navigate(["../"], { relativeTo: this.route });
                    }
                })
            );
        }

        if (this.MODE === BaseComponentMode.EDIT) {
            this.subscriptions.push(
                this.pointsOfInterestService.update(this.pointOfInterest._id, this.pointOfInterest).subscribe((result) => {
                    const uploadSubscriptions = this.uploadPictureFiles(result);

                    if (uploadSubscriptions.length > 0) {
                        this.subscriptions.push(
                            forkJoin(uploadSubscriptions).subscribe(
                                (results) => {
                                    this.alertService.addSuccess(this.translateService.instant("pointsofinterest.detail.edit.success"));
                                    this.router.navigate(["../"], { relativeTo: this.route });
                                },
                                (error) => {
                                    this.initEditMode();
                                }
                            )
                        );
                    } else {
                        this.alertService.addSuccess(this.translateService.instant("pointsofinterest.detail.edit.success"));
                        this.router.navigate(["../"], { relativeTo: this.route });
                    }
                })
            );
        }
    }

    private uploadPictureFiles(result: PointOfInterest): Observable<PointOfInterest>[] {
        const uploadSubscriptions: Observable<PointOfInterest>[] = [];

        if (this.selectedTeaserImage && this.selectedTeaserImage.length > 0) {
            const formData: FormData = new FormData();
            const file: File = this.selectedTeaserImage[0];
            formData.append("file", file, file.name);

            uploadSubscriptions.push(this.pointsOfInterestService.uploadPicture(result._id, formData));
        }

        if (this.selectedLogoImage && this.selectedLogoImage.length > 0) {
            const formData: FormData = new FormData();
            const file: File = this.selectedLogoImage[0];
            formData.append("file", file, file.name);

            uploadSubscriptions.push(this.pointsOfInterestService.uploadLogo(result._id, formData));
        }

        if (this.selectedThumbnailImage && this.selectedThumbnailImage.length > 0) {
            const formData: FormData = new FormData();
            const file: File = this.selectedThumbnailImage[0];
            formData.append("file", file, file.name);

            uploadSubscriptions.push(this.pointsOfInterestService.uploadThumbnail(result._id, formData));
        }

        return uploadSubscriptions;
    }

    back() {
        this._location.back();
    }
}

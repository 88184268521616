import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { IApiListQueryParameter, BaseDatatableStateSaveMode } from "@impacgroup/angular-baselib";
import { map } from "rxjs/operators";
import { classToPlain, plainToClass } from "class-transformer";
import { Category } from "../../api-models/Category";
import { ApiResponseHandlerTool } from "../../global/api-handling/ApiResponseHandlerTool";

export interface ICategoryConfigService {
    categoriesAPI: string;
    utcDateFormat: string;
    datatableStateSaveMode: BaseDatatableStateSaveMode;
}

export const CategoryConfigService = new InjectionToken<ICategoryConfigService>("CategoryConfig");

export interface ILanguage {
    code: string;
    name: string;
}
export interface ILanguagesConfigService {
    getAllLanguages: () => Observable<ILanguage[]>;
}
export const LanguagesConfigService = new InjectionToken<ILanguagesConfigService>("LanguagesConfigService");

@Injectable()
export class CategoriesService {
    public UTCDATEFORMAT = "";
    public datatableStateSaveMode: BaseDatatableStateSaveMode;

    constructor(
        @Inject(CategoryConfigService) private readonly categoryConfig: ICategoryConfigService,
        @Inject(LanguagesConfigService) private readonly languagesConfigService: ILanguagesConfigService,
        private readonly http: HttpClient,
        private readonly oauthStorageAdapter: OAuthStorageAdapter
    ) {
        this.UTCDATEFORMAT = this.categoryConfig.utcDateFormat;
        this.datatableStateSaveMode = this.categoryConfig.datatableStateSaveMode;
    }

    public list(params: IApiListQueryParameter): Observable<{ list: Category[]; count: number; total: number }> {
        return this.http
            .get<any>(this.getCategoriesServiceUrl(""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params: params as any,
                observe: "response"
            })
            .pipe(
                map((result) => {
                    const { count, total } = ApiResponseHandlerTool.getPaginatedListHeader(result);

                    return {
                        list: plainToClass(Category, result.body as any[]),
                        count: count,
                        total: total
                    };
                })
            );
    }

    public detail(id: string): Observable<Category> {
        return this.http
            .get<Object>(this.getCategoriesServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(Category, result)));
    }

    public add(category: Category): Observable<Category> {
        return this.http
            .post<Object>(this.getCategoriesServiceUrl(""), classToPlain(category), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(Category, result)));
    }

    public update(category: Category): Observable<Category> {
        return this.http
            .patch<Object>(this.getCategoriesServiceUrl(category._id), classToPlain(category), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(Category, result)));
    }

    public delete(id: string): Observable<Category> {
        return this.http
            .delete<Object>(this.getCategoriesServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(Category, result)));
    }

    public languages() {
        return this.languagesConfigService.getAllLanguages();
    }

    private getCategoriesServiceUrl(postfix: string): string {
        return this.categoryConfig.categoriesAPI + postfix;
    }
}

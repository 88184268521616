import * as tslib_1 from "tslib";
import { Exclude, Type } from "class-transformer";
import { nonLambdaReturn } from "@impacgroup/angular-baselib";
var IPointOfInterestTranslation = /** @class */ (function () {
    function IPointOfInterestTranslation() {
        this.iso = "";
        this.information = "";
    }
    return IPointOfInterestTranslation;
}());
export { IPointOfInterestTranslation };
export var MarkerType;
(function (MarkerType) {
    MarkerType["standard"] = "standard";
    MarkerType["christmas_tree"] = "christmas_tree";
})(MarkerType || (MarkerType = {}));
var PointOfInterest = /** @class */ (function () {
    function PointOfInterest() {
        this.name = "";
        this.country = "";
        this.street = "";
        this.postalcode = "";
        this.city = "";
        this.owner = "";
        this.phone = "";
        this.email = "";
        this.website = "";
        this.tags = [];
        this.translations = [];
        this.category = "";
        this.gpsLat = 0;
        this.gpsLong = 0;
        this.markerType = MarkerType.standard;
    }
    tslib_1.__decorate([
        Type(nonLambdaReturn(Date)),
        Exclude({ toPlainOnly: true }),
        tslib_1.__metadata("design:type", Date)
    ], PointOfInterest.prototype, "created", void 0);
    tslib_1.__decorate([
        Type(nonLambdaReturn(Date)),
        Exclude({ toPlainOnly: true }),
        tslib_1.__metadata("design:type", Date)
    ], PointOfInterest.prototype, "updated", void 0);
    return PointOfInterest;
}());
export { PointOfInterest };

import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { CategoriesService } from "./categories.service";
import { AlertService, BaseDatatableComponent, ConfirmDialogComponent } from "@impacgroup/angular-baselib";
import { CategoriesMainComponent } from "./categories.main.component";
var CategoriesComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CategoriesComponent, _super);
    function CategoriesComponent(categoriesService, translateService, router, route, modalService, alertService, parent) {
        var _this = _super.call(this, translateService, {
            stateSaveMode: categoriesService.datatableStateSaveMode,
            stateSaveComponent: parent,
            stateSaveStorageKey: "dtstate_categories_list"
        }) || this;
        _this.categoriesService = categoriesService;
        _this.translateService = translateService;
        _this.router = router;
        _this.route = route;
        _this.modalService = modalService;
        _this.alertService = alertService;
        _this.UTCDATEFORMAT = "";
        _this.rows = [];
        _this.dtOptions.order = [[2, "desc"]];
        _this.UTCDATEFORMAT = _this.categoriesService.UTCDATEFORMAT;
        return _this;
    }
    CategoriesComponent.prototype.ngOnInit = function () { };
    CategoriesComponent.prototype._dataTableTrigger = function () {
        var _this = this;
        return function (dataTableParameters, callback) {
            // get api query parameters by datatable
            var queryParams = _this.getApiQueryParamsByDataTable(dataTableParameters);
            _this.subscriptions.push(_this.categoriesService.list(queryParams).subscribe(function (result) {
                _this.rows = result.list;
                callback({
                    recordsTotal: result.total,
                    recordsFiltered: result.count,
                    data: []
                });
            }));
        };
    };
    CategoriesComponent.prototype._dataTableColums = function () {
        return [
            { data: "key", orderable: true },
            { data: "color", orderable: true },
            { data: "created", orderable: true },
            { data: "updated", orderable: true },
            { data: "actions", orderable: false }
        ];
    };
    CategoriesComponent.prototype.addCategory = function () {
        this.router.navigate(["add"], { relativeTo: this.route });
    };
    CategoriesComponent.prototype.editCategory = function (id) {
        this.router.navigate([id], { relativeTo: this.route });
    };
    CategoriesComponent.prototype.deleteCategory = function (id) {
        var _this = this;
        var initialState = {
            title: this.translateService.instant("categories.list.delete.title"),
            message: this.translateService.instant("categories.list.delete.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        var bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState: initialState });
        bsModalRef.content.confirmCallback = function () {
            _this.subscriptions.push(_this.categoriesService.delete(id).subscribe(function (result) {
                _this.alertService.addSuccess(_this.translateService.instant("categories.list.delete.success"));
                _this.rerenderDatatable();
            }));
        };
    };
    return CategoriesComponent;
}(BaseDatatableComponent));
export { CategoriesComponent };

import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { IApiListQueryParameter, BaseDatatableStateSaveMode } from "@impacgroup/angular-baselib";
import { map } from "rxjs/operators";
import { plainToClass, classToPlain } from "class-transformer";
import { PointOfInterest } from "../../api-models/PointOfInterest";
import { Category } from "../../api-models/Category";
import { ApiResponseHandlerTool } from "../../global/api-handling/ApiResponseHandlerTool";

export interface IPointOfInterestConfigService {
    pointsOfInterestAPI: string;
    utcDateFormat: string;
    datatableStateSaveMode: BaseDatatableStateSaveMode;
}

export const PointOfInterestConfigService = new InjectionToken<IPointOfInterestConfigService>("PointOfInterestConfig");

export interface ICountry {
    code: string;
    name: string;
}
export interface IPointOfInterestCountriesConfigService {
    getAllCountries: () => Observable<ICountry[]>;
}
export const PointOfInterestCountriesConfigService = new InjectionToken<IPointOfInterestCountriesConfigService>("PointOfInterestCountriesConfigService");

export interface ILanguage {
    code: string;
    name: string;
}
export interface ILanguagesConfigService {
    getAllLanguages: () => Observable<ILanguage[]>;
}
export const LanguagesConfigService = new InjectionToken<ILanguagesConfigService>("LanguagesConfigService");

@Injectable()
export class PointsOfInterestService {
    public UTCDATEFORMAT = "";
    public datatableStateSaveMode: BaseDatatableStateSaveMode;

    constructor(
        @Inject(PointOfInterestConfigService) private readonly pointOfInterestConfig: IPointOfInterestConfigService,
        @Inject(PointOfInterestCountriesConfigService) private readonly pointOfInterestCountriesConfigService: IPointOfInterestCountriesConfigService,
        @Inject(LanguagesConfigService) private readonly languagesConfigService: ILanguagesConfigService,
        private readonly http: HttpClient,
        private readonly oauthStorageAdapter: OAuthStorageAdapter
    ) {
        this.UTCDATEFORMAT = this.pointOfInterestConfig.utcDateFormat;
        this.datatableStateSaveMode = this.pointOfInterestConfig.datatableStateSaveMode;
    }

    public list(params: IApiListQueryParameter): Observable<{ list: PointOfInterest[]; count: number; total: number }> {
        return this.http
            .get<any>(this.getPointOfInterestsServiceUrl(""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params: params as any,
                observe: "response"
            })
            .pipe(
                map((result) => {
                    const { count, total } = ApiResponseHandlerTool.getPaginatedListHeader(result);

                    return {
                        list: plainToClass(PointOfInterest, result.body as any[]),
                        count: count,
                        total: total
                    };
                })
            );
    }

    public detail(id: string): Observable<PointOfInterest> {
        return this.http
            .get<Object>(this.getPointOfInterestsServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(PointOfInterest, result)));
    }

    public add(poi: PointOfInterest): Observable<PointOfInterest> {
        return this.http
            .post<Object>(this.getPointOfInterestsServiceUrl(""), classToPlain(poi), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(PointOfInterest, result)));
    }

    public update(id: string, poi: PointOfInterest): Observable<PointOfInterest> {
        return this.http
            .patch<Object>(this.getPointOfInterestsServiceUrl(id), classToPlain(poi), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(PointOfInterest, result)));
    }

    public uploadPicture(id: string, formData: FormData): Observable<PointOfInterest> {
        return this.http
            .post<Object>(this.getPointOfInterestsServiceUrl(`${id}/picture`), formData, {
                headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType()
            })
            .pipe(map((result) => plainToClass(PointOfInterest, result)));
    }

    public uploadLogo(id: string, formData: FormData): Observable<PointOfInterest> {
        return this.http
            .post<Object>(this.getPointOfInterestsServiceUrl(`${id}/logo`), formData, {
                headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType()
            })
            .pipe(map((result) => plainToClass(PointOfInterest, result)));
    }

    public uploadThumbnail(id: string, formData: FormData): Observable<PointOfInterest> {
        return this.http
            .post<Object>(this.getPointOfInterestsServiceUrl(`${id}/thumbnail`), formData, {
                headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType()
            })
            .pipe(map((result) => plainToClass(PointOfInterest, result)));
    }

    public delete(id: string): Observable<PointOfInterest> {
        return this.http
            .delete<Object>(this.getPointOfInterestsServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(PointOfInterest, result)));
    }

    public downloadPicture(id: string): Observable<Blob> {
        return this.http.get(this.getPointOfInterestsServiceUrl(`${id}/picture`), {
            headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType(),
            responseType: "blob"
        });
    }

    public downloadLogo(id: string): Observable<Blob> {
        return this.http.get(this.getPointOfInterestsServiceUrl(`${id}/logo`), {
            headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType(),
            responseType: "blob"
        });
    }

    public downloadThumbnail(id: string): Observable<Blob> {
        return this.http.get(this.getPointOfInterestsServiceUrl(`${id}/thumbnail`), {
            headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType(),
            responseType: "blob"
        });
    }

    public countries() {
        return this.pointOfInterestCountriesConfigService.getAllCountries();
    }

    public languages() {
        return this.languagesConfigService.getAllLanguages();
    }

    public categories(): Observable<Category[]> {
        return this.http
            .get<Object[]>(this.getPointOfInterestsServiceUrl("categories/"), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(Category, result)));
    }

    private getPointOfInterestsServiceUrl(postfix: string): string {
        return this.pointOfInterestConfig.pointsOfInterestAPI + postfix;
    }
}

import { ModalModule } from "ngx-bootstrap/modal";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { DataTablesModule } from "angular-datatables";

import { CategoriesComponent } from "./categories.component";
import { CategoriesService, CategoryConfigService, ICategoryConfigService, LanguagesConfigService } from "./categories.service";
import { CategoryDetailComponent } from "./categorydetail.component";
import { TranslateModule } from "@ngx-translate/core";
import { OAuthModule } from "@impacgroup/angular-oauth-base";
import { Route, RouterModule } from "@angular/router";
import { CategoriesMainComponent } from "./categories.main.component";
import { LanguagesService } from "@impacgroup/angular-languages";
import { TabsModule } from "ngx-bootstrap/tabs";

@NgModule({
    declarations: [CategoriesMainComponent, CategoriesComponent, CategoryDetailComponent],
    imports: [CommonModule, RouterModule, FormsModule, DataTablesModule, BsDropdownModule, ModalModule, NgSelectModule, TranslateModule, TabsModule, OAuthModule],
    exports: [],
    providers: []
})
export class CategoriesModule {
    static forRoot(categoryConfig: ICategoryConfigService): ModuleWithProviders {
        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: CategoriesModule,
            providers: [
                CategoriesService,
                {
                    provide: CategoryConfigService,
                    useValue: categoryConfig
                },
                {
                    provide: LanguagesConfigService,
                    useClass: LanguagesService
                }
            ]
        };
    }

    static getRoutes(): Route[] {
        return [
            {
                path: "",
                component: CategoriesMainComponent,
                children: [
                    {
                        path: "",
                        component: CategoriesComponent
                    },
                    {
                        path: "add",
                        component: CategoryDetailComponent
                    },
                    {
                        path: ":id",
                        component: CategoryDetailComponent
                    }
                ]
            }
        ];
    }
}

import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { PointsOfInterestService } from "./pointsofinterest.service";
import { BaseDatatableComponent, AlertService, ConfirmDialogComponent } from "@impacgroup/angular-baselib";
import { PointsOfInterestMainComponent } from "./pointsofinterest.main.component";
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
var PointsOfInterestComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PointsOfInterestComponent, _super);
    function PointsOfInterestComponent(router, route, pointsOfInterestService, modalService, alertService, translateService, parent) {
        var _this = _super.call(this, translateService, {
            stateSaveMode: pointsOfInterestService.datatableStateSaveMode,
            stateSaveComponent: parent,
            stateSaveStorageKey: "dtstate_pointsofinterest_list"
        }) || this;
        _this.router = router;
        _this.route = route;
        _this.pointsOfInterestService = pointsOfInterestService;
        _this.modalService = modalService;
        _this.alertService = alertService;
        _this.translateService = translateService;
        _this.UTCDATEFORMAT = "";
        _this.rows = [];
        _this.dtOptions.order = [[4, "desc"]];
        _this.UTCDATEFORMAT = _this.pointsOfInterestService.UTCDATEFORMAT;
        return _this;
    }
    PointsOfInterestComponent.prototype.ngOnInit = function () { };
    PointsOfInterestComponent.prototype._dataTableTrigger = function () {
        var _this = this;
        return function (dataTableParameters, callback) {
            // get api query parameters by datatable
            var queryParams = _this.getApiQueryParamsByDataTable(dataTableParameters);
            _this.subscriptions.push(_this.pointsOfInterestService.list(queryParams).subscribe(function (result) {
                _this.rows = result.list;
                callback({
                    recordsTotal: result.total,
                    recordsFiltered: result.count,
                    data: []
                });
            }));
        };
    };
    PointsOfInterestComponent.prototype._dataTableColums = function () {
        return [
            { data: "name", orderable: true },
            { data: "postalcode", orderable: true },
            { data: "city", orderable: true },
            { data: "country", orderable: true },
            { data: "created", orderable: true },
            { data: "updated", orderable: true },
            { data: "actions", orderable: false }
        ];
    };
    PointsOfInterestComponent.prototype.add = function () {
        this.router.navigate(["add"], { relativeTo: this.route });
    };
    PointsOfInterestComponent.prototype.edit = function (id) {
        this.router.navigate([id], { relativeTo: this.route });
    };
    PointsOfInterestComponent.prototype.delete = function (id) {
        var _this = this;
        var initialState = {
            title: this.translateService.instant("pointsofinterest.list.delete.title"),
            message: this.translateService.instant("pointsofinterest.list.delete.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        var bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState: initialState });
        bsModalRef.content.confirmCallback = function () {
            _this.subscriptions.push(_this.pointsOfInterestService.delete(id).subscribe(function (result) {
                _this.alertService.addSuccess(_this.translateService.instant("pointsofinterest.list.delete.success"));
                _this.rerenderDatatable();
            }));
        };
    };
    return PointsOfInterestComponent;
}(BaseDatatableComponent));
export { PointsOfInterestComponent };

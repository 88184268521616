<div class="content__inner">
    <header class="content__title">
        <h1 *ngIf="MODE === 'ADD'">{{ 'pointsofinterest.detail.add.title' | translate }}</h1>
        <h1 *ngIf="MODE === 'EDIT'">{{ 'pointsofinterest.detail.edit.title' | translate }}</h1>

        <div class="actions"> 
            <button (click)="back()" class="btn btn-secondary btn--icon-text"><i class="zmdi zmdi-arrow-back"></i> {{ 'global.buttons.back' | translate }}</button>
        </div>
    </header>

    <div class="card">
        <div class="card-body">
            <form name="form" class="row" *ngIf="pointOfInterest" (ngSubmit)="f.form.valid && save()" #f="ngForm" novalidate>
                <div class="col-md-12">
                            
                    <div class="form-group">
                        <label>{{ 'pointsofinterest.props.picture' | translate }}</label><br/>
                        <img [src]="teaserPreviewImage" *ngIf="teaserPreviewImage" style="max-height:225px; max-width:100%;" alt="{{ 'pointsofinterest.props.picture' | translate }}" /><br *ngIf="teaserPreviewImage" />
                        <button (click)="fileInput.click()" class="btn btn-success btn--icon-text mt-1" type="button">
                            <i class="zmdi zmdi-collection-plus"></i> {{ 'pointsofinterest.props.pictureDataFile' | translate }}
                            <input #fileInput type="file"
                            accept='image/*'
                            id="file" style="display:none;"
                            class="form-control"
                            (change)="handleTeaserFileInput($event.target.files)">
                        </button>
                    </div>

                </div>

                <div class="col-md-6">
                            
                    <div class="form-group">
                        <label>{{ 'pointsofinterest.props.logo' | translate }}</label><br/>
                        <img [src]="logoPreviewImage" *ngIf="logoPreviewImage" style="max-height:225px; max-width:100%;" alt="{{ 'pointsofinterest.props.logo' | translate }}" /><br *ngIf="logoPreviewImage" />
                        <button (click)="logoFileInput.click()" class="btn btn-success btn--icon-text mt-1" type="button">
                            <i class="zmdi zmdi-collection-plus"></i> {{ 'pointsofinterest.props.pictureDataFile' | translate }}
                            <input #logoFileInput type="file"
                            accept='image/*'
                            id="logo" style="display:none;"
                            class="form-control"
                            (change)="handleLogoFileInput($event.target.files)">
                        </button>
                    </div>

                </div>

                <div class="col-md-6">
                            
                    <div class="form-group">
                        <label>{{ 'pointsofinterest.props.thumbnail' | translate }}</label><br/>
                        <img [src]="thumbnailPreviewImage" *ngIf="thumbnailPreviewImage" style="max-height:225px; max-width:100%;" alt="{{ 'pointsofinterest.props.thumbnail' | translate }}" /><br *ngIf="thumbnailPreviewImage" />
                        <button (click)="thumbnailFileInput.click()" class="btn btn-success btn--icon-text mt-1" type="button">
                            <i class="zmdi zmdi-collection-plus"></i> {{ 'pointsofinterest.props.pictureDataFile' | translate }}
                            <input #thumbnailFileInput type="file"
                            accept='image/*'
                            id="thumbnail" style="display:none;"
                            class="form-control"
                            (change)="handleThumbnailFileInput($event.target.files)">
                        </button>
                    </div>

                </div>

                <div class="col-md-6">

                    <div class="form-group">
                        <label>{{ 'pointsofinterest.props.name' | translate }}</label>
                        <input name="name" type="text" class="form-control" [(ngModel)]="pointOfInterest.name" #name="ngModel" [ngClass]="{ 'is-invalid': f.submitted && name.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ 'pointsofinterest.detail.validation.name' | translate }}
                        </div>
                    </div>

                </div>

                <div class="col-md-6">

                    <div class="form-group">
                        <label>{{ 'pointsofinterest.props.street' | translate }}</label>
                        <input name="street" type="text" class="form-control" [(ngModel)]="pointOfInterest.street" #street="ngModel" [ngClass]="{ 'is-invalid': f.submitted && street.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ 'pointsofinterest.detail.validation.street' | translate }}
                        </div>
                    </div>

                </div>

                <div class="col-md-6">

                    <div class="form-group">
                        <label>{{ 'pointsofinterest.props.postalcode' | translate }}</label>
                        <input name="postalcode" type="text" class="form-control" [(ngModel)]="pointOfInterest.postalcode" #postalcode="ngModel" [ngClass]="{ 'is-invalid': f.submitted && postalcode.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ 'pointsofinterest.detail.validation.postalcode' | translate }}
                        </div>
                    </div>

                </div>

                <div class="col-md-6">

                    <div class="form-group">
                        <label>{{ 'pointsofinterest.props.city' | translate }}</label>
                        <input name="city" type="text" class="form-control" [(ngModel)]="pointOfInterest.city" #city="ngModel" [ngClass]="{ 'is-invalid': f.submitted && city.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ 'pointsofinterest.detail.validation.city' | translate }}
                        </div>
                    </div>

                </div>

                <div class="col-md-6">

                    <div class="form-group">
                        <label>{{ 'pointsofinterest.props.country' | translate }}</label>
                        <ng-select
                            name="country"
                            [items]="countries"
                            bindLabel="name"
                            bindValue="code"
                            placeholder=""
                            [(ngModel)]="pointOfInterest.country" 
                            #country="ngModel" [ngClass]="{ 'is-invalid': f.submitted && country.invalid }" required>
                        </ng-select>
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ 'pointsofinterest.detail.validation.country' | translate }}
                        </div>
                    </div>

                </div>

                <div class="col-md-6">

                    <div class="form-group">
                        <label>{{ 'pointsofinterest.props.category' | translate }}</label>
                        <ng-select
                            name="category"
                            [items]="categories"
                            bindLabel="key"
                            bindValue="_id"
                            placeholder=""
                            [(ngModel)]="pointOfInterest.category"
                            #category="ngModel" [ngClass]="{ 'is-invalid': f.submitted && category.invalid }" required >
                        </ng-select>
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ 'pointsofinterest.detail.validation.category' | translate }}
                        </div>
                    </div>

                </div>

                <div class="col-md-6">

                    <div class="form-group">
                        <label>{{ 'pointsofinterest.props.gpsLat' | translate }}</label>
                        <input name="gpsLat" type="number" class="form-control" [(ngModel)]="pointOfInterest.gpsLat" #gpsLat="ngModel" [ngClass]="{ 'is-invalid': f.submitted && gpsLat.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ 'pointsofinterest.detail.validation.gpsLat' | translate }}
                        </div>
                    </div>

                </div>

                <div class="col-md-6">

                    <div class="form-group">
                        <label>{{ 'pointsofinterest.props.gpsLong' | translate }}</label>
                        <input name="gpsLong" type="number" class="form-control" [(ngModel)]="pointOfInterest.gpsLong" #gpsLong="ngModel" [ngClass]="{ 'is-invalid': f.submitted && gpsLong.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ 'pointsofinterest.detail.validation.gpsLong' | translate }}
                        </div>
                    </div>

                </div>

                <div class="col-md-6">

                    <div class="form-group">
                        <label>{{ 'pointsofinterest.props.owner' | translate }}</label>
                        <input name="owner" type="text" class="form-control" [(ngModel)]="pointOfInterest.owner" />
                        <i class="form-group__bar"></i>
                    </div>

                </div>

                <div class="col-md-6">

                    <div class="form-group">
                        <label>{{ 'pointsofinterest.props.phone' | translate }}</label>
                        <input name="phone" type="text" class="form-control" [(ngModel)]="pointOfInterest.phone" />
                        <i class="form-group__bar"></i>
                    </div>

                </div>

                <div class="col-md-6">

                    <div class="form-group">
                        <label>{{ 'pointsofinterest.props.email' | translate }}</label>
                        <input name="email" type="email" class="form-control" [(ngModel)]="pointOfInterest.email" />
                        <i class="form-group__bar"></i>
                    </div>

                </div>

                <div class="col-md-6">

                    <div class="form-group">
                        <label>{{ 'pointsofinterest.props.website' | translate }}</label>
                        <input name="website" type="url" class="form-control" [(ngModel)]="pointOfInterest.website" />
                        <i class="form-group__bar"></i>
                    </div>

                </div>

                <div class="col-md-6">

                    <div class="form-group">
                        <label>{{ 'pointsofinterest.props.tags' | translate }}</label>
                        <input name="tags" type="text" class="form-control" [(ngModel)]="tags" />
                        <i class="form-group__bar"></i>
                    </div>

                </div>

                <div class="col-md-6">

                    <div class="form-group">
                        <label>{{ 'pointsofinterest.props.markerType' | translate }}</label>
                        <ng-select
                            name="markerType"
                            [items]="markerTypes"
                            placeholder=""
                            [(ngModel)]="pointOfInterest.markerType"
                            #markerType="ngModel" [ngClass]="{ 'is-invalid': f.submitted && markerType.invalid }" required >
                        </ng-select>
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ 'pointsofinterest.detail.validation.markerType' | translate }}
                        </div>
                    </div>

                </div>

                <div class="col-md-12">
                    <tabset>
                        <tab *ngFor="let translation of pointOfInterest.translations; let idx = index" [heading]="languageNameByIso(translation.iso)">

                            <div class="form-group">
                                <label>{{ 'pointsofinterest.props.information' | translate }}</label>
                                <quill-editor name="information_{{idx}}" [(ngModel)]="pointOfInterest.translations[idx].information"></quill-editor>
                                <i class="form-group__bar"></i>
                            </div>

                        </tab>
                    </tabset>
                </div>

                <div class="col-md-12">
                    <button class="float-right btn btn-success btn--icon-text" [disabled]="f.submitted && f.invalid"><i class="zmdi zmdi-check"></i> {{ 'global.buttons.save' | translate }}</button>
                </div>

            </form>
        </div>
    </div>
</div>
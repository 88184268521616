import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { DataTablesModule } from "angular-datatables";

import { PointsOfInterestComponent } from "./pointsofinterest.component";
import { PointsOfInterestService, PointOfInterestConfigService, IPointOfInterestConfigService, PointOfInterestCountriesConfigService, LanguagesConfigService } from "./pointsofinterest.service";
import { TranslateModule } from "@ngx-translate/core";
import { LanguagesService } from "@impacgroup/angular-languages";
import { OAuthModule } from "@impacgroup/angular-oauth-base";
import { Route, RouterModule } from "@angular/router";
import { PointsOfInterestMainComponent } from "./pointsofinterest.main.component";
import { PointOfInterestDetailComponent } from "./pointofinterestdetail.component";
import { StandardCountryService } from "../../global/services/StandardCountryService";
import { TabsModule } from "ngx-bootstrap/tabs";
import { QuillModule } from "ngx-quill";

@NgModule({
    declarations: [PointsOfInterestMainComponent, PointsOfInterestComponent, PointOfInterestDetailComponent],
    imports: [CommonModule, RouterModule, FormsModule, DataTablesModule, BsDropdownModule, ModalModule, NgSelectModule, TranslateModule, TabsModule, OAuthModule, QuillModule],
    exports: [],
    providers: []
})
export class PointsOfInterestModule {
    static forRoot(pointOfInterestConfig: IPointOfInterestConfigService): ModuleWithProviders {
        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: PointsOfInterestModule,
            providers: [
                PointsOfInterestService,
                {
                    provide: PointOfInterestConfigService,
                    useValue: pointOfInterestConfig
                },
                {
                    provide: PointOfInterestCountriesConfigService,
                    useClass: StandardCountryService
                },
                {
                    provide: LanguagesConfigService,
                    useClass: LanguagesService
                }
            ]
        };
    }

    static getRoutes(): Route[] {
        return [
            {
                path: "",
                component: PointsOfInterestMainComponent,
                children: [
                    {
                        path: "",
                        component: PointsOfInterestComponent
                    },
                    {
                        path: "add",
                        component: PointOfInterestDetailComponent
                    },
                    {
                        path: ":id",
                        component: PointOfInterestDetailComponent
                    }
                ]
            }
        ];
    }
}

<div class="content__inner">
    <header class="content__title">
        <h1>{{ 'categories.list.title' | translate }}</h1>
        <small>{{ 'categories.list.subtitle' | translate }}</small>


        <div class="actions"> 
            <button *appHasPermission="'CATEGORIES.ADD'" (click)="addCategory()" class="btn btn-success btn--icon-text"><i class="zmdi zmdi-collection-plus"></i> {{ 'categories.list.add' | translate }}</button>
        </div>
    </header>

    <div class="card" *appHasPermission="'CATEGORIES.LIST'">
        <div class="card-body">
            <h4 class="card-title">{{ 'categories.list.card.title' | translate }}</h4>

            <table datatable class="table table-bordered" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th scope="col">{{ 'categories.props.key' | translate }}</th>
                        <th scope="col">{{ 'categories.props.color' | translate }}</th>
                        <th scope="col">{{ 'categories.props.created' | translate }}</th>
                        <th scope="col">{{ 'categories.props.updated' | translate }}</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody *ngIf="rows?.length != 0">
                    <tr *ngFor="let row of rows">
                        <td>{{ row.key }}</td>
                        <td>{{ row.color }}</td>
                        <td>{{ row.created | date:UTCDATEFORMAT:'+0' }}</td>
                        <td>{{ row.updated | date:UTCDATEFORMAT:'+0' }}</td>
                        <td class="text-right nowrap">
                            <i (click)="editCategory(row._id)" class="zmdi zmdi-edit zmdi-hc-fw tableIcon" *appHasPermission="'CATEGORIES.EDIT'"></i>
                            <i (click)="deleteCategory(row._id)" class="zmdi zmdi-delete zmdi-hc-fw tableIcon" *appHasPermission="'CATEGORIES.DELETE'"></i>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="rows?.length == 0">
                    <tr>
                        <td colspan="4" class="no-data-available">{{ 'global.datatable.empty' | translate }}</td>
                    </tr>
                <tbody>
            </table>
        </div>
    </div>
</div>
import * as tslib_1 from "tslib";
import { Exclude, Type } from "class-transformer";
import { nonLambdaReturn } from "@impacgroup/angular-baselib";
var ICategoryTranslation = /** @class */ (function () {
    function ICategoryTranslation() {
        this.iso = "";
        this.name = "";
    }
    return ICategoryTranslation;
}());
export { ICategoryTranslation };
var Category = /** @class */ (function () {
    function Category() {
        this.key = "";
        this.color = "";
        this.translations = [];
    }
    tslib_1.__decorate([
        Type(nonLambdaReturn(Date)),
        Exclude({ toPlainOnly: true }),
        tslib_1.__metadata("design:type", Date)
    ], Category.prototype, "created", void 0);
    tslib_1.__decorate([
        Type(nonLambdaReturn(Date)),
        Exclude({ toPlainOnly: true }),
        tslib_1.__metadata("design:type", Date)
    ], Category.prototype, "updated", void 0);
    return Category;
}());
export { Category };

import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, BaseComponent, BaseComponentMode } from "@impacgroup/angular-baselib";
import { PointOfInterest, MarkerType } from "../../api-models/PointOfInterest";
import { PointsOfInterestService } from "./pointsofinterest.service";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin } from "rxjs";
var PointOfInterestDetailComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PointOfInterestDetailComponent, _super);
    function PointOfInterestDetailComponent(route, pointsOfInterestService, router, alertService, translateService, _location) {
        var _this = _super.call(this) || this;
        _this.route = route;
        _this.pointsOfInterestService = pointsOfInterestService;
        _this.router = router;
        _this.alertService = alertService;
        _this.translateService = translateService;
        _this._location = _location;
        _this.pointOfInterestId = null;
        _this.pointOfInterest = null;
        _this.tags = "";
        _this.selectedTeaserImage = null;
        _this.teaserPreviewImage = null;
        _this.selectedLogoImage = null;
        _this.logoPreviewImage = null;
        _this.selectedThumbnailImage = null;
        _this.thumbnailPreviewImage = null;
        _this.countries = [];
        _this.languages = [];
        _this.categories = [];
        _this.markerTypes = [];
        _this.markerTypes = Object.keys(MarkerType).map(function (key) { return MarkerType[key]; });
        _this.subscriptions.push(_this.pointsOfInterestService.languages().subscribe(function (languages) {
            _this.languages = languages;
            _this.subscriptions.push(_this.route.params.subscribe(function (params) {
                _this.pointOfInterestId = params.id;
                if (_this.pointOfInterestId) {
                    _this.initEditMode();
                }
                else {
                    _this.initAddMode();
                }
            }));
        }));
        return _this;
    }
    PointOfInterestDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.pointsOfInterestService.countries().subscribe(function (result) {
            _this.countries = result;
        }));
        this.subscriptions.push(this.pointsOfInterestService.categories().subscribe(function (result) {
            _this.categories = result;
        }));
    };
    PointOfInterestDetailComponent.prototype.initAddMode = function () {
        var _this = this;
        this.MODE = BaseComponentMode.ADD;
        this.pointOfInterest = new PointOfInterest();
        this.languages.forEach(function (language) {
            _this.pointOfInterest.translations.push({
                iso: language.code,
                information: ""
            });
        });
    };
    PointOfInterestDetailComponent.prototype.initEditMode = function () {
        var _this = this;
        this.MODE = BaseComponentMode.EDIT;
        this.selectedTeaserImage = null;
        this.selectedLogoImage = null;
        this.selectedThumbnailImage = null;
        this.subscriptions.push(this.pointsOfInterestService.detail(this.pointOfInterestId).subscribe(function (result) {
            _this.pointOfInterest = result;
            if (result.tags) {
                _this.tags = result.tags.join(", ");
            }
            if (_this.pointOfInterest.teaser && _this.pointOfInterest.teaser.picture) {
                _this.subscriptions.push(_this.pointsOfInterestService.downloadPicture(_this.pointOfInterestId).subscribe(function (teaser) {
                    var reader = new FileReader();
                    reader.readAsDataURL(teaser);
                    reader.onload = function (_event) {
                        _this.teaserPreviewImage = reader.result;
                    };
                }));
            }
            if (_this.pointOfInterest.logo && _this.pointOfInterest.logo.picture) {
                _this.subscriptions.push(_this.pointsOfInterestService.downloadLogo(_this.pointOfInterestId).subscribe(function (logo) {
                    var reader = new FileReader();
                    reader.readAsDataURL(logo);
                    reader.onload = function (_event) {
                        _this.logoPreviewImage = reader.result;
                    };
                }));
            }
            if (_this.pointOfInterest.thumbnail && _this.pointOfInterest.thumbnail.picture) {
                _this.subscriptions.push(_this.pointsOfInterestService.downloadThumbnail(_this.pointOfInterestId).subscribe(function (thumbnail) {
                    var reader = new FileReader();
                    reader.readAsDataURL(thumbnail);
                    reader.onload = function (_event) {
                        _this.thumbnailPreviewImage = reader.result;
                    };
                }));
            }
            var translations = [];
            _this.languages.forEach(function (language) {
                var found = _this.pointOfInterest.translations.find(function (translation) { return translation.iso === language.code; });
                if (found) {
                    translations.push(found);
                }
                else {
                    translations.push({
                        iso: language.code,
                        information: ""
                    });
                }
            });
            _this.pointOfInterest.translations = translations;
        }, function (err) {
            _this.router.navigate(["../"], { relativeTo: _this.route });
        }));
    };
    PointOfInterestDetailComponent.prototype.languageNameByIso = function (isoCode) {
        var found = this.languages.find(function (lang) { return lang.code === isoCode; });
        return found ? found.name : "";
    };
    // handle teaser file selection
    PointOfInterestDetailComponent.prototype.handleTeaserFileInput = function (files) {
        var _this = this;
        if (files.length === 0) {
            return;
        }
        var mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            this.alertService.addCustomErrorByI18nId("pointsofinterest.detail.validation.picture");
            return;
        }
        var reader = new FileReader();
        this.selectedTeaserImage = files;
        reader.readAsDataURL(files[0]);
        reader.onload = function (_event) {
            _this.teaserPreviewImage = reader.result;
        };
    };
    // handle logo file selection
    PointOfInterestDetailComponent.prototype.handleLogoFileInput = function (files) {
        var _this = this;
        if (files.length === 0) {
            return;
        }
        var mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            this.alertService.addCustomErrorByI18nId("pointsofinterest.detail.validation.logo");
            return;
        }
        var reader = new FileReader();
        this.selectedLogoImage = files;
        reader.readAsDataURL(files[0]);
        reader.onload = function (_event) {
            _this.logoPreviewImage = reader.result;
        };
    };
    // handle thumbnail file selection
    PointOfInterestDetailComponent.prototype.handleThumbnailFileInput = function (files) {
        var _this = this;
        if (files.length === 0) {
            return;
        }
        var mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            this.alertService.addCustomErrorByI18nId("pointsofinterest.detail.validation.thumbnail");
            return;
        }
        var reader = new FileReader();
        this.selectedThumbnailImage = files;
        reader.readAsDataURL(files[0]);
        reader.onload = function (_event) {
            _this.thumbnailPreviewImage = reader.result;
        };
    };
    PointOfInterestDetailComponent.prototype.save = function () {
        var _this = this;
        this.pointOfInterest.tags = this.tags.split(",").map(function (tag) { return tag.trim(); });
        if (this.MODE === BaseComponentMode.ADD) {
            // teaser picture is mandatory
            if (!this.selectedTeaserImage || this.selectedTeaserImage.length === 0) {
                this.alertService.addCustomError(this.translateService.instant("pointsofinterest.detail.validation.picture"));
                return;
            }
            this.subscriptions.push(this.pointsOfInterestService.add(this.pointOfInterest).subscribe(function (result) {
                var uploadSubscriptions = _this.uploadPictureFiles(result);
                if (uploadSubscriptions.length > 0) {
                    _this.subscriptions.push(forkJoin(uploadSubscriptions).subscribe(function (results) {
                        _this.alertService.addSuccess(_this.translateService.instant("pointsofinterest.detail.add.success"));
                        _this.router.navigate(["../"], { relativeTo: _this.route });
                    }, function (error) {
                        _this.router.navigate(["../", result._id], { relativeTo: _this.route });
                    }));
                }
                else {
                    _this.alertService.addSuccess(_this.translateService.instant("pointsofinterest.detail.add.success"));
                    _this.router.navigate(["../"], { relativeTo: _this.route });
                }
            }));
        }
        if (this.MODE === BaseComponentMode.EDIT) {
            this.subscriptions.push(this.pointsOfInterestService.update(this.pointOfInterest._id, this.pointOfInterest).subscribe(function (result) {
                var uploadSubscriptions = _this.uploadPictureFiles(result);
                if (uploadSubscriptions.length > 0) {
                    _this.subscriptions.push(forkJoin(uploadSubscriptions).subscribe(function (results) {
                        _this.alertService.addSuccess(_this.translateService.instant("pointsofinterest.detail.edit.success"));
                        _this.router.navigate(["../"], { relativeTo: _this.route });
                    }, function (error) {
                        _this.initEditMode();
                    }));
                }
                else {
                    _this.alertService.addSuccess(_this.translateService.instant("pointsofinterest.detail.edit.success"));
                    _this.router.navigate(["../"], { relativeTo: _this.route });
                }
            }));
        }
    };
    PointOfInterestDetailComponent.prototype.uploadPictureFiles = function (result) {
        var uploadSubscriptions = [];
        if (this.selectedTeaserImage && this.selectedTeaserImage.length > 0) {
            var formData = new FormData();
            var file = this.selectedTeaserImage[0];
            formData.append("file", file, file.name);
            uploadSubscriptions.push(this.pointsOfInterestService.uploadPicture(result._id, formData));
        }
        if (this.selectedLogoImage && this.selectedLogoImage.length > 0) {
            var formData = new FormData();
            var file = this.selectedLogoImage[0];
            formData.append("file", file, file.name);
            uploadSubscriptions.push(this.pointsOfInterestService.uploadLogo(result._id, formData));
        }
        if (this.selectedThumbnailImage && this.selectedThumbnailImage.length > 0) {
            var formData = new FormData();
            var file = this.selectedThumbnailImage[0];
            formData.append("file", file, file.name);
            uploadSubscriptions.push(this.pointsOfInterestService.uploadThumbnail(result._id, formData));
        }
        return uploadSubscriptions;
    };
    PointOfInterestDetailComponent.prototype.back = function () {
        this._location.back();
    };
    return PointOfInterestDetailComponent;
}(BaseComponent));
export { PointOfInterestDetailComponent };

import { HttpResponse } from "@angular/common/http";

export class ApiResponseHandlerTool {
    public static getPaginatedListHeader(response: HttpResponse<any>): { count: number; total: number } {
        let count = response.body.length;
        let total = response.body.length;

        try {
            count = parseInt(response.headers.get("X-Pagination-Count"), 10);
        } catch (e) {}
        try {
            total = parseInt(response.headers.get("X-Total-Count"), 10);
        } catch (e) {}

        return { count, total };
    }
}

import { Component } from "@angular/core";
import { BaseComponent, IDatatableStateSaveableComponent } from "@impacgroup/angular-baselib";

@Component({
    selector: "app-pointsofinterest-main",
    templateUrl: "./pointsofinterest.main.component.html"
})
export class PointsOfInterestMainComponent extends BaseComponent implements IDatatableStateSaveableComponent {
    public listStateData: any;
}

import { Component, OnInit, Host } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { CategoriesService } from "./categories.service";
import { AlertService, BaseDatatableComponent, ConfirmDialogComponent, IDataTableParameters } from "@impacgroup/angular-baselib";
import { Category } from "../../api-models/Category";
import { CategoriesMainComponent } from "./categories.main.component";

@Component({
    selector: "app-categories",
    templateUrl: "./categories.component.html",
    styleUrls: ["categories.component.scss"]
})
export class CategoriesComponent extends BaseDatatableComponent implements OnInit {
    public UTCDATEFORMAT = "";

    rows: Category[] = [];

    constructor(
        private readonly categoriesService: CategoriesService,
        private readonly translateService: TranslateService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly modalService: BsModalService,
        private readonly alertService: AlertService,
        @Host() parent: CategoriesMainComponent
    ) {
        super(translateService, {
            stateSaveMode: categoriesService.datatableStateSaveMode,
            stateSaveComponent: parent,
            stateSaveStorageKey: "dtstate_categories_list"
        });

        this.dtOptions.order = [[2, "desc"]];
        this.UTCDATEFORMAT = this.categoriesService.UTCDATEFORMAT;
    }

    ngOnInit() {}

    _dataTableTrigger() {
        return (dataTableParameters: IDataTableParameters, callback) => {
            // get api query parameters by datatable
            const queryParams = this.getApiQueryParamsByDataTable(dataTableParameters);

            this.subscriptions.push(
                this.categoriesService.list(queryParams).subscribe((result) => {
                    this.rows = result.list;

                    callback({
                        recordsTotal: result.total,
                        recordsFiltered: result.count,
                        data: []
                    });
                })
            );
        };
    }

    _dataTableColums() {
        return [
            { data: "key", orderable: true },
            { data: "color", orderable: true },
            { data: "created", orderable: true },
            { data: "updated", orderable: true },
            { data: "actions", orderable: false }
        ];
    }

    addCategory() {
        this.router.navigate(["add"], { relativeTo: this.route });
    }

    editCategory(id: string) {
        this.router.navigate([id], { relativeTo: this.route });
    }

    deleteCategory(id: string) {
        const initialState = {
            title: this.translateService.instant("categories.list.delete.title"),
            message: this.translateService.instant("categories.list.delete.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        bsModalRef.content.confirmCallback = () => {
            this.subscriptions.push(
                this.categoriesService.delete(id).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("categories.list.delete.success"));
                    this.rerenderDatatable();
                })
            );
        };
    }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./pointsofinterest.main.component";
var styles_PointsOfInterestMainComponent = [];
var RenderType_PointsOfInterestMainComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PointsOfInterestMainComponent, data: {} });
export { RenderType_PointsOfInterestMainComponent as RenderType_PointsOfInterestMainComponent };
export function View_PointsOfInterestMainComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_PointsOfInterestMainComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-pointsofinterest-main", [], null, null, null, View_PointsOfInterestMainComponent_0, RenderType_PointsOfInterestMainComponent)), i0.ɵdid(1, 180224, null, 0, i2.PointsOfInterestMainComponent, [], null, null)], null, null); }
var PointsOfInterestMainComponentNgFactory = i0.ɵccf("app-pointsofinterest-main", i2.PointsOfInterestMainComponent, View_PointsOfInterestMainComponent_Host_0, {}, {}, []);
export { PointsOfInterestMainComponentNgFactory as PointsOfInterestMainComponentNgFactory };

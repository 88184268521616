import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, BaseComponent, BaseComponentMode } from "@impacgroup/angular-baselib";
import { CategoriesService, ILanguage } from "./categories.service";
import { TranslateService } from "@ngx-translate/core";
import { Category } from "../../api-models/Category";

@Component({
    selector: "app-categorydetail",
    templateUrl: "./categorydetail.component.html",
    styleUrls: ["categorydetail.component.scss"]
})
export class CategoryDetailComponent extends BaseComponent implements OnInit {
    private categoryId: string = null;
    public category: Category = null;

    public languages: ILanguage[] = [];

    constructor(
        private readonly route: ActivatedRoute,
        private readonly categoriesService: CategoriesService,
        private readonly router: Router,
        private readonly alertService: AlertService,
        private readonly translateService: TranslateService,
        private readonly _location: Location
    ) {
        super();

        this.subscriptions.push(
            this.categoriesService.languages().subscribe((languages) => {
                this.languages = languages;

                this.subscriptions.push(
                    this.route.params.subscribe((params) => {
                        this.categoryId = params.id;
                        if (this.categoryId) {
                            this.initEditMode();
                        } else {
                            this.initAddMode();
                        }
                    })
                );
            })
        );
    }

    ngOnInit(): void {}

    initAddMode() {
        this.MODE = BaseComponentMode.ADD;
        this.category = new Category();

        this.languages.forEach((language) => {
            this.category.translations.push({
                iso: language.code,
                name: ""
            });
        });
    }

    initEditMode() {
        this.MODE = BaseComponentMode.EDIT;

        this.subscriptions.push(
            this.categoriesService.detail(this.categoryId).subscribe(
                (result) => {
                    this.category = result;

                    const translations = [];
                    this.languages.forEach((language) => {
                        const found = this.category.translations.find((translation) => translation.iso === language.code);
                        if (found) {
                            translations.push(found);
                        } else {
                            translations.push({
                                iso: language.code,
                                name: ""
                            });
                        }
                    });
                    this.category.translations = translations;
                },
                (err) => {
                    this.router.navigate(["../"], { relativeTo: this.route });
                }
            )
        );
    }

    languageNameByIso(isoCode: string) {
        const found = this.languages.find((lang) => lang.code === isoCode);
        return found ? found.name : "";
    }

    save() {
        if (this.MODE === BaseComponentMode.ADD) {
            this.subscriptions.push(
                this.categoriesService.add(this.category).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("categories.detail.add.success"));
                    this.router.navigate(["../"], { relativeTo: this.route });
                })
            );
        }

        if (this.MODE === BaseComponentMode.EDIT) {
            this.subscriptions.push(
                this.categoriesService.update(this.category).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("categories.detail.edit.success"));
                    this.router.navigate(["../"], { relativeTo: this.route });
                })
            );
        }
    }

    back() {
        this._location.back();
    }
}

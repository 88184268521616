import { Exclude, Type } from "class-transformer";
import { nonLambdaReturn } from "@impacgroup/angular-baselib";

export class ICategoryTranslation {
    _id?: string;
    iso = "";
    name = "";
}

export class Category {
    _id?: string;
    key = "";
    color = "";
    translations: ICategoryTranslation[] = [];
    version?: number;

    @Type(nonLambdaReturn(Date))
    @Exclude({ toPlainOnly: true })
    created?: Date;

    @Type(nonLambdaReturn(Date))
    @Exclude({ toPlainOnly: true })
    updated?: Date;
}
